.userData {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    border: 1px solid #fff;
    text-align: center;
    margin-left: 20px;

    p {
        margin: 0;
        line-height: 1;
    }

    .network {
        margin-right: 10px;
        
        small {
            color: #7aff7f;
            height: 10px;
        }
    }

    > button {
        font-size: 10px;
    }
}