.linktree {
    color: initial;

    > p {
        margin-top: 10px;
    }
}

.videos {
    margin-bottom: 30px;

    .video {
        iframe {
            border-radius: 20px;
            overflow: hidden;
            background-color: #000;
        }
    }
}